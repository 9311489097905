<template>
  <div role="main" class="main">
    <div v-if="!isSafari"> 
      <section class="common-page minh-100 home-page" style="background: none" v-show="page == 'home'">
        <div
          class="container max-height d-flex align-items-center justify-content-center"
        >
          <div class="row flex-grow-1">
            <transition name="fade">
              <div class="col-12 text-center" >
                <h1 class="mb-0 text-white" style="letter-spacing: 0.3rem;">
                  WED WITH US
                </h1>
                <h3 class="text-white text-overline">
                  AN INCLUSIVE ONLINE WEDDING EXPERIENCE
                </h3>
              </div>
            </transition>
          </div>
        </div>
      </section>
      <section class="registration" v-show="page == 'profile'">
        <div class="registration-page-content common-page">
          <div class="container c-column-flex">
            <div class="row" v-if="event_info!=null">
              <div class="col-12 text-left page-heading">
                <h3 class="m-0">Guestbook Registration</h3>
                <p class="m-0 text-white">
                  Wedding day: {{ event_info.detail.start_date_time }}  
                      {{ event_info.detail.time_zone }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div
                  class="custom-group d-flex align-items-center justify-content-center"
                >
                  <div class="photo-preview d-flex align-items-center">
                    <div class="upload-photo mr-3" v-if="!avatar_url">
                      Your photo in here
                    </div>
                    <div
                      class="flower mr-3"
                      v-if="avatar_url"
                      style="margin-bottom: 0.5rem"
                    >
                      <img :src="avatar_url" class="img-fluid round-image" />
                    </div>
                    <div class="photo-action text-left">
                      <input
                        type="file"
                        ref="avatarFile"
                        @change="inputFile"
                        accept="image/x-png,image/gif,image/jpeg"
                        style="display: none"
                      />
                      <b-button
                        type="button"
                        class="btn-primary white-border"
                        @click="$refs.avatarFile.click()"
                        >Upload your picture</b-button
                      >
                      <h6 class="text-primary mt-2">
                        Or select your avatar from below avatars
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="d-flex align-items-center justify-content-center">
                  <!--carousel slider start-->
                  <v-carousel v-model="imageNumber" @change="here()">
                    <!-- <v-carousel-item
                      v-for="(item, i) in items"
                      :key="i"
                      :src="item.src"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="uploadCarouselImage(item)"
                    ></v-carousel-item> -->
                    <!-- <img :src="require('@/assets/img/flower1.png')" class="img-fluid" @click="setImage('flower1.png')"> -->
                    <v-carousel-item
                      :src="require('@/assets/img/wed1.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed1.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed2.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed2.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed3.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed3.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed4.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed4.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed5.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed5.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed6.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed6.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed7.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed7.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed8.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed8.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed9.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed9.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed10.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed10.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed11.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed11.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed12.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed12.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed13.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed13.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed14.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed14.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed15.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed15.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed16.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed16.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed17.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed17.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed18.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed18.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed19.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed19.png')"
                    ></v-carousel-item>
                    <v-carousel-item
                      :src="require('@/assets/img/wed20.png')"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="setImage('wed20.png')"
                    ></v-carousel-item>
                    <div class="arrow-icon-wrapper mr-3 prev-icon">
                      <img src="../../assets/icons/caret-left-solid_white.svg" />
                    </div>
                    <div class="arrow-icon-wrapper ml-3 next-icon">
                      <img src="../../assets/icons/caret-right-solid_white.svg" />
                    </div>
                    
                  </v-carousel>
                  <!--carousel slider end-->
                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="d-flex justify-content-center">
                  <div class="custom-group mb-2">
                    <input
                      type="text"
                      v-model="profile.full_name"
                      class="form-control form-input-control white-border"
                      placeholder="Type your name here"
                    />
                  </div>
                  <div>
                    <b-button
                      type="button"
                      class="btn-primary ml-2 white-border"
                      @click="onSubmit()"
                      :disabled="profile.full_name == ''"
                      >Submit</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="registration-page-footer">
          <div class="footer-wrapper container-fluid">
            <div class="row justify-content-center">
              <!-- <div class="col-lg-3"></div> -->
              <div class="col-lg-3 d-flex justify-content-center" v-if="eventItem.gift_pay_url!=''">
                <div class="img-avatar-banner"  @click="goNext('donation')">
                  <img
                    src="../../assets/img/DonateWithPaypal.png"
                    class="img-fluid banner-avatar round-image"
                  />
                  <div class="banner-name">Gift with <span>PayPal</span></div>
                </div>
              </div>
              <!-- <div class="col-lg-3">
                <div class="img-avatar-banner">
                  <img
                    src="../../assets/img/upload-photo.png"
                    class="img-fluid banner-avatar round-image"
                  />
                  <div class="banner-name" @click="goNext('upload_photo')">
                    Upload a photo
                  </div>
                </div>
              </div> -->
              <div class="col-lg-3 d-flex justify-content-center" v-if="eventItem.reminder_url!=''">
                <div class="img-avatar-banner" @click="goNext('calendar')">
                  <img
                    src="../../assets/img/calendar.png"
                    class="img-fluid banner-avatar round-image"
                  />
                  <div class="banner-name">Calendar reminder</div>
                </div>
              </div>
              <div class="col-lg-3 d-flex justify-content-center" v-if="eventItem.gift_registry_url!=''">
                <div class="img-avatar-banner" @click="goNext('shop')">
                  <img
                    src="../../assets/img/gift.png"
                    class="img-fluid banner-avatar round-image"
                  />
                  <div class="banner-name">Gift Registry</div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </section>
    </div>
    <transition>
      <div
        class="modal fade show program-modal"
        v-if="isSafari"
        id="myModal"
        role="dialog"
        aria-labelledby="myModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
                <span>Please use Chrome browser for this link</span> 
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl, liveBaseUrl, } from "../../constants/config";
import { mapActions } from "vuex";
import moment from "moment";
import Vue from 'vue'
import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

export default {
  data() {
    return {
      page: "home",
      profile: {
        event_id: 0,
        full_name: "",
        image: null,
        type: "default",
        profile_url: "",
      },
      avatar_url: null,
      previous_info: null,
      submit_btn: null,
      event_info: null,
      connection: null,
      isSafari: false,
      imageNumber: null,
      event_id:'',
      eventItem: {    
        gift_pay_url: "",
        gift_registry_url: "",
        reminder_url: "",
      },
    };
  },
  mounted() {
    this.avatar_url = require("@/assets/img/wed1.png");
    this.profile.profile_url = "wed1.png";
    this.profile.type = "default";
    this.profile.image = "wed1.png";
    if(this.$browserDetect.isSafari){
      console.log('microsoft edge profile');
      this.isSafari = true
    }
    console.log("lllll",this.$route.params.id)
    this.profile.event_id = this.getCorrectNumber(this.$route.params.id);
    this.previous_info =
      localStorage.getItem("loginInfo") != null
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;
        console.log("[[[[[]]]]]",this.previous_info)
        getApiManager()
      .post(`${apiBaseUrl}/api/visitor/profile/get-event`, {
        event_id: this.profile.event_id,
      })
      .then((response) => {
        let res = response.data;
        this.eventItem = res.detail;
        this.event_info = response.data;
        this.event_info.detail.start_date_time = this.getFormatedDate();
        if (res.status === true) {
          console.log("event", res);
          this.event_id=res.detail.event_id
          console.log("lllddd",this.event_id)
          if (
      this.previous_info &&
      this.previous_info.role == "visitor" &&
      this.previous_info.event_id == this.profile.event_id
    ) {
      let randomnumber = Math.floor(Math.random() * 10000) + 1;
      this.$router.push("/visitor/dashboard/"+this.event_id+"?x=" + randomnumber);
    } else localStorage.removeItem("loginInfo");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    
    
    window.setTimeout(() => {
      this.page = "";
    }, 3000);
    window.setTimeout(() => {
      this.page = "profile";
    }, 4000);
    this.connectSocket();
  },
  methods: {
    ...mapActions(["setLoggedInfo"]),
    getCorrectNumber(text) {
      return window.atob(text);
    },
    getFormatedDate() {
      const formateddate=new Date(this.event_info.detail.start_date_time)
      const firstdayMonth = formateddate.getMonth() + 1;
      const firstdayDay = formateddate.getDate();
      const firstdayYear = formateddate.getFullYear();
      const time = new Date(this.event_info.detail.start_date_time).toLocaleTimeString('en', { timeStyle: 'short', hour12: true, timeZone: 'UTC' });
      return ("0000" + firstdayYear.toString()).slice(-4) + "-" + ("00" + firstdayMonth.toString()).slice(-2) + "-" + ("00" + firstdayDay.toString()).slice(-2)+" "+time
      
    },
    here(){
      if(this.imageNumber>=0){
        let count=parseInt(this.imageNumber)+1
        this.avatar_url = require("@/assets/img/wed"+count+".png");
        this.profile.profile_url = "wed"+count+".png";
        this.profile.type = "default";
        this.profile.image = "wed"+count+".png";
      }
    },
    setImage(item) {
      this.profile.profile_url = item;
      this.profile.type = "default";
      this.profile.image = item;
      this.avatar_url = require("@/assets/img/" + item);
      this.submit_btn = "allow";
    },
    inputFile(event) {
      this.profile.image = event.target.files[0];
      this.profile.type = "random";
      this.submit_btn = "allow";
      //set image to the div area
      var reader = new FileReader();
      var ref = this;
      reader.onload = function(e) {
        ref.avatar_url = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    save() {
      if (this.profile.full_name == "") {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Error",
          text: "Please input a name.",
          animation_type: "slide",
        });
        return;
      }
    },
    back() {
      this.$router.push("/home");
    },
     goNext(page) {
      if (page == "condolence") {
        if (this.message == "") {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Waring",
            text: "Please input the message.",
            animation_type: "slide",
          });
          return;
        }
        getApiManager()
          .post(`${apiBaseUrl}/api/visitor/condolence/create`, {
            event_id: this.event_id,
            message: this.message,
            visitor_id: this.visitor_id,
          })
          .then((response) => {
            let res = response.data;
            if (res.status === true) {
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/visitor/condolence/" + res.condolence_id+"/"+res.event_id+"?x=" + randomnumber);
            } else {
              this.$notify({
                group: "foo",
                type: "warn",
                title: "Error",
                text: "Server Error",
                animation_type: "slide",
              });
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (page == "upload_photo"){ 
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/visitor/" + page+"?x=" + randomnumber);
    }
      // else if(page=="shop")
      //   window.open(this.event_info.gift_registry_url,"_blank");
      // else if (page=="donation")
      //   window.open(this.event_info.gift_pay_url,"_blank");
      // else if(page=="calendar")
      //   alert("coming soon")
      else if (page == "shop") {
        window.open(this.event_info.detail.gift_registry_url, "_blank");
      } else if (page == "donation") {
        window.open(this.event_info.detail.gift_pay_url, "_blank");
      } else if (page == "calendar") {
        window.open(this.event_info.detail.reminder_url, "_blank");
      } else {
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/visitor/page/" + page+"?x=" + randomnumber);
      }
    },
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    onSubmit() {
      if (this.profile.image == null) {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Error",
          text: "Please select an image",
          animation_type: "slide",
        });
        return;
      }
      if (this.profile.full_name == "") {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Error",
          text: "Please input a name",
          animation_type: "slide",
        });
        return;
      }
      const formData = new FormData();
      for (let key in this.profile) {
        formData.append(key, this.profile[key]);
      }
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/profile/create`, formData)
        .then((response) => {
          let res = response.data;
          console.log("66666666",res)
          if (res.status === true) {
            this.setLoggedInfo({
              token: res.id,
              name: this.profile.full_name,
              role: "visitor",
              event_id: this.profile.event_id,
            });
            this.connection.send(
              JSON.stringify({
                type: "update_visitors",
              })
            );
            localStorage.setItem("wed_name", res.name);
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push("/visitor/dashboard/"+this.event_id+"?x=" + randomnumber);
          } else {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
            return;
          }
        })
        .catch((error) => {
           this.$notify({
              group: "foo",
              type: "warn",
              title: "Warning",
              text: "Please upload image less then 1 mb",
              animation_type: "slide",
            });
          console.log(error);
        });
    },
  },
  components: {},
  computed: {},
  watch: {},
};
</script>
<style>
.select-profile-img {
  height: 125px;
  width: 115px;
  min-height: 125px;
  min-width: 115px;
  object-fit: cover;
  border: 3px solid #fff;
}
.upload-photo {
  color: #b7a261;
  background-color: #ffffff;
  font-size: 12px;
  border-radius: 50%;
  height: 75px;
  width: 75px;
  border: 2px solid #b7a261;
  padding: 8px;
  text-align: center;
  margin-bottom: 0.5rem;
}
@font-face {
  font-family: "Fontin-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Fontin-Regular.woff") format("woff");
}
@font-face {
  font-family: "Fontin-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Fontin-Bold.woff") format("woff");
}
.registration-page-content {
  height: calc(100vh - 135px);
  padding-top: 9rem;
  padding-bottom: 4rem;
  background-image: url("../../assets/img/back-img.png") !important;
  background-size: contain;
}
.registration-page-footer {
  position: relative;
  background-image: url("../../assets/img/footer-img.png");
  background-repeat: repeat-y;
  background-position: left top;
  background-size: contain;
  padding: 15px 0;
  border-image-slice: 1;
  border-top: 5px solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(119, 40, 3, 1) 0%,
    rgba(163, 62, 11, 1) 15%,
    rgba(195, 85, 12, 1) 33%,
    rgba(102, 29, 3, 1) 59%,
    rgba(102, 21, 3, 1) 64%,
    rgba(102, 29, 3, 1) 76%,
    rgba(157, 57, 6, 1) 88%
  );
}
.registration-page-footer::before {
  content: "";
  height: 100%;
  width: 100%;
  background: #000000b0;
  position: absolute;
  top: 0;
  left: 0;
}
.page-heading h3 {
  color: #fff;
  font-family: "Fontin-Regular";
}
.arrow-icon-wrapper {
  height: 24px;
  width: 24px;
}
.avatar-img img {
  height: 150px;
  width: 150px;
  border: 3px solid #fff;
}
.white-border {
  border: 1px solid #fff;
  color: #fff;
}
.img-avatar-banner {
  display: flex;
  align-items: center;
}
.banner-avatar {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 2px solid #fff;
  position: relative;
  z-index: 2;
}
.banner-name {
  color: #fff;
  font-weight: 400;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px 30px;
  position: relative;
  margin-left: -23px;
  z-index: 1;
  cursor: pointer;
}
.banner-name span {
  font-weight: 900;
}
.footer-wrapper {
  /* padding: 0 12vw; */
}
.c-column-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.v-carousel {
  min-width: 200px !important;
  width: 200px !important;
  min-height: 200px !important;
  height: 200px !important;
}
.v-carousel__controls {
  display: none !important;
}
.v-window__next {
  right: -50px;
}
.v-window__prev {
  left: -50px;
}
.v-window-item {
  border-radius: 200px !important;
  min-width: 200px !important;
  width: 200px !important;
  min-height: 200px !important;
  height: 200px !important;
  overflow: hidden;
  border: 5px solid #fff;
}
.v-window {
  overflow: visible !important;
}
.v-image {
  height: 200px !important;
}
.v-window__next,
.v-window__prev {
  background: transparent;
  border-radius: 50%;
  position: absolute;
  margin: 0 !important;
}
.prev-icon {
  position: absolute;
  left: -40px;
  top: calc(50% - 26px);
}
.next-icon {
  position: absolute;
  right: -40px;
  top: calc(50% - 26px);
}
button:focus {
  outline: none;
}
.v-window__next .v-btn:hover,
.v-window__prev .v-btn:hover {
  opacity: 0.1;
}
@media screen and (min-width: 1800px) {
  .v-window-item {
    border-radius: 300px !important;
    min-width: 300px !important;
    width: 300px !important;
    min-height: 300px !important;
    height: 300px !important;
    overflow: hidden;
    border: 5px solid #fff;
  }
  .v-carousel {
    min-width: 300px !important;
    width: 300px !important;
    min-height: 300px !important;
    height: 300px !important;
  }
  .v-image {
    height: 300px !important;
  }
  .arrow-icon-wrapper {
    height: 36px;
    width: 36px;
  }
  .v-btn--icon.v-size--default {
    height: 60px;
  }
  .next-icon {
    right: -50px;
  }
  .prev-icon {
    left: -50px;
  }
  .v-window__prev {
    left: -60px;
  }
  .v-window__next {
    right: -60px;
  }
}
</style>
